const _useColors = (theme) => {
	const allColor = Object.assign({}, theme);
	Object.keys(theme).forEach((key) => {
		document.documentElement.style.setProperty(`--${key}`, allColor[key])
	})
};

/**
    @function
    @param {object} theme - An object containing CSS custom properties and their values.
    @returns {void}
    @example
    useColors({
    primaryColor: "#00f",
    secondaryColor: "#0f0",
    });
    */
export const useColors = createSharedComposable(_useColors)