export enum BodyStyle {
    Bus = "33",
    Sprinter = "34",
    Van = "56"
}

export enum SupplierType {
    TecRmi = 3
}

export enum EstimateContentType {
    Product = 1,
    LaborTime = 2,
    VariousTimes = 3,
    Ingredients = 4,
    Plan = 5
}

export enum CountryCode {
    France = 1001
}

export enum SlicedDeliveryPriceType {
    Price = 1,
    Weight = 2
}
export enum KindOfWorkTime {
    DecimalWorkHours = 0,
    ManufacturerWorkPositions = 1
}

export enum TermSubCategory {
    Available = 1,
    AvailableSoon = 2,
    AvailableDepot = 3
}

export enum TermCategory {
    Disponibilities = 1
}

export enum SupplierTypes {
    AppService = 1,
    CataloguePa = 2,
    Data = 3,
    Erp = 4,
    Siv = 5,
    Cart = 6
}

export enum StockAvailabilityStatusCode {
    Available = 1,
    AvailableSoon = 2,
    NotAvailable = 3,
    InLogistics = 4
}