export default defineAppConfig({
    query: {
      actions: {
        newVehicle: 'newVehicle'
      }
    },
    version: "v1.4.822-P",
    maxImagesShop: 5,
    ui: {
        primary: 'app'
    }
})