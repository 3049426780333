import { defineStore } from 'pinia'
import { SupplierTypes } from '~/types/enums'
import type { AppMode } from '~/types/global'
import type { Config, Links, SupplierStockStatus } from '~/types/interfaces'

export const useConfigStore = defineStore({
  id: 'config',
  state: () => ({
    config: <Config | undefined>undefined,
    supplierStockStatus: <SupplierStockStatus[]>[],
    appMode: <AppMode>'development',
    wsDataBaseUrl: '',
    wsCartBaseUrl: '',
    wsErpBaseUrl: '',
    wsSivBaseUrl: '',
    wsCatalogPaBaseUrl: '',
    links: <Links>{
        home: "/tableau-de-bord",
        dashboard: "/tableau-de-bord",
        login: "/login",
        signup: "/signup",
        catalog: "/catalog",
        catalogWarehouseman: "/catalogue/search-warehouseman",
        contact: "/contact",
        cart: "/panier",
        devis: "/quotes/general/new",
        devisHistory: "/quotes/general/history",
        devisDraft: "/quotes/general/draft",
        devisEdit: "/quotes/edit/",
        devisShow: "/quotes/show/",
        devisPrint: "/imprimer-pdf",
        ListCustomer:"/activite/clients",
        Showcustomer: "/activite/clients/show/",
        Editcustomer: "/activite/clients/edit/",
        maintenance: "/catalog/maintenance",
        maintenanceConfiguration: "/catalog/maintenance/configuration",
        maintenanceWorks: "/catalog/maintenance/works",
        maintenancePlan: "/catalog/maintenance/plan",
        manualRepairs: "/catalog/manuals",
        productList: "/products",
        orders: "/activite/commandes",
        order: "/commande",
        vehicle: "/activite/vehicules",
        legalMentions: "",
        account: "/account",
        accountSubscription: "/account/subscription",
        accountRate: "/account/rates",
        accountCustom: "/account/custom",
        accountCustomPackages: "/account/custom/packages",
        accountCustomParts: "/account/custom/parts",
        times: '/catalog/times',
        tires: '/pneumatique',
        tiresList: '/pneumatique/products',
        shop: "/boutique",
        shopSearch: "/boutique/products",
        printMaintenance: "/print/maintenance",
        autopartspro: "https://autopartspro.fr/"
    }
  }),
  actions: {
    setConfig(newConfig: Config, newSupplierStockStatus: SupplierStockStatus[], cdnUrl: string) {
        this.config = newConfig
        this.supplierStockStatus = newSupplierStockStatus

        this.appMode = 
            newConfig.establishment.stateMode === 1 ? 'production' :
            newConfig.establishment.stateMode === 2 ? 'development' :
            'maintenance'

        this.setWsLinks(newConfig)
        this.links.cdnUrl = cdnUrl 
    },
    setWsLinks(config: Config) {

        const cartSupplier = config.suppliers?.filter(supplier => supplier.typeId === SupplierTypes.Cart)[0]
        const erpSupplier = config.suppliers?.filter(supplier => supplier.typeId === SupplierTypes.Erp)[0]
        const sivSupplier = config.suppliers?.filter(supplier => supplier.typeId === SupplierTypes.Siv)[0]
        const cataloguePaSupplier = config.suppliers?.filter(supplier => supplier.typeId === SupplierTypes.CataloguePa)[0]
        const dataSupplier = config.suppliers?.filter(supplier => supplier.typeId === SupplierTypes.Data)[0]

        this.wsCartBaseUrl = cartSupplier && this.appMode === 'development' ?
            cartSupplier.devFrontCallEndPoint :
            cartSupplier ? cartSupplier.frontCallEndPoint : ""
       
        this.wsErpBaseUrl = erpSupplier && this.appMode === 'development' ? 
            erpSupplier.devFrontCallEndPoint
            : erpSupplier ? erpSupplier.frontCallEndPoint : ""

        this.wsSivBaseUrl = sivSupplier && this.appMode === 'development' ?
            sivSupplier.devFrontCallEndPoint
            : sivSupplier ? sivSupplier.frontCallEndPoint : ""
       
        this.wsCatalogPaBaseUrl = cataloguePaSupplier && this.appMode === 'development' ?
            cataloguePaSupplier.devFrontCallEndPoint 
            : cataloguePaSupplier ? cataloguePaSupplier.frontCallEndPoint : ""
       
        this.wsDataBaseUrl = dataSupplier && this.appMode === 'development' ? 
            dataSupplier.devFrontCallEndPoint
            : dataSupplier ? dataSupplier.frontCallEndPoint : ""        
    }
  },
})