import type {DirectiveBinding} from "vue";

export default defineNuxtPlugin(({vueApp}) => {
    vueApp.directive('float', {
        mounted(el: HTMLInputElement, binding: DirectiveBinding<{ decimal: number, min: number }>, vnode, prevVnode) {

            const handlerInput = (e: any) => {

                //prevent recursive event
                if(e.detail) return

                const floatRegex = /^\d*\.?\d{0,2}$/;
                const numberRgx = /[^0-9.]/g;

                if(!e.target.value) e.target.value = binding?.value?.min ?? 0

                //replace comma by dot
                e.target.value = e.target.value.replace(/,/g, '.')

                if(floatRegex.test(e.target.value)) {
                    e.target.dispatchEvent(new CustomEvent('input', { detail: true }))
                    el.addEventListener('input', handlerInput)
                    return
                }

                const start = e.target.selectionStart;
                const end = e.target.selectionEnd;
                e.target.value = e.target.value.replace(numberRgx, '')

                const parts = e.target.value.split('.')

                //remove dot if more than one
                if (parts.length > 2) {
                    e.target.value = parts[0] + '.' + parts.slice(1).join('').replace(/\./g, '')
                }

                // limit to binding decimal if not undefined
                if (binding?.value?.decimal && parts.length === 2) {
                    e.target.value = parts[0] + '.' + parts[1].slice(0, binding.value.decimal)
                }

                e.target.setSelectionRange(start, end)

                e.target.dispatchEvent(new CustomEvent('input', { detail: true }))
            };

            el.addEventListener('input', handlerInput)
        }
    });

    vueApp.directive('int', {
        mounted(el: HTMLInputElement, binding: DirectiveBinding<{ min: number, max: number }>, vnode, prevVnode) {
            const handlerInput = (e: any) => {
                // Prevent recursive event
                if (e.detail) return;
    
                const integerRegex = /^\d*$/;
                const numberRgx = /[^0-9]/g;
    
                if (!e.target.value) e.target.value = binding?.value?.min ?? 0;
                if (binding?.value?.max && (e.target.value > binding.value.max)) e.target.value = binding.value.max;
    
                if (integerRegex.test(e.target.value)) {
                    e.target.dispatchEvent(new CustomEvent('input', { detail: true }));
                    el.addEventListener('input', handlerInput);
                    return;
                }
    
                const start = e.target.selectionStart;
                const end = e.target.selectionEnd;
                e.target.value = e.target.value.replace(numberRgx, '');
    
                e.target.setSelectionRange(start, end);
    
                e.target.dispatchEvent(new CustomEvent('input', { detail: true }));
            };
    
            el.addEventListener('input', handlerInput);
        }
    });
    

    vueApp.directive('percentage', {
        mounted(el: HTMLInputElement) {
            const handlerInput = (e: any) => {

                //prevent recursive event
                if(e.detail) return

                const numberRgx = /^[0-9]*$/;
                const invalidCharsRgx = /[^0-9]/g;

                if(!e.target.value) e.target.value = 0

                if (!numberRgx.test(e.target.value)) {
                    const start = e.target.selectionStart;
                    const end = e.target.selectionEnd;
                    e.target.value = e.target.value.replace(invalidCharsRgx, '');
                    e.target.setSelectionRange(start, end);
                }

                let value = parseFloat(e.target.value);
                if (value < 0 || value > 100) {
                    const start = e.target.selectionStart;
                    const end = e.target.selectionEnd;
                    if (value < 0) {
                        e.target.value = '0';
                    } else if (value > 100) {
                        e.target.value = '100';
                    }
                    e.target.setSelectionRange(start, end);
                }

                e.target.dispatchEvent(new CustomEvent('input', { detail: true }));
            };

            el.addEventListener('input', handlerInput)
        }
    })
})