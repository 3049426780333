export default defineNuxtRouteMiddleware(async (to, from) => {
    const config = useRuntimeConfig()
    const { links } = useConfigStore() 
    const userToken = useCookie(config.public.cookies.userToken);

    if (!userToken.value || userToken.value === '') {
        if (to.fullPath === links.login || to.fullPath === links.signup) {
            return
        } else {
            return navigateTo(links.login);
        }
    }   

    const { data: isLogged, error } = await useFetch("/api/auth/checkLoggin");
    
    if (error.value || !isLogged.value && to.fullPath !== links.login && to.fullPath !== links.signup) {
        return navigateTo(links.login);
    }
});