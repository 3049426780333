import { useConfigStore } from '@/store/useConfigStore'
import type { Config, SupplierStockStatus } from '~/types/interfaces'

export default defineNuxtPlugin(async () => {
    const { data: configResponse, status } = await useFetch<Config>('/api/configs/getConfigs')
    const { data: supplierStockResponse } = await useFetch<SupplierStockStatus[]>( `/api/configs/availabilityFilters`, { default: () => [] })

    const configStore = useConfigStore()
    const { wsDataBaseUrl, wsCartBaseUrl, wsErpBaseUrl, wsSivBaseUrl, wsCatalogPaBaseUrl } = storeToRefs(configStore)
    const { log } = useConsole()
    const { cdnUrl, cookies } = useRuntimeConfig().public
    
    const wsDataBaseUrlCookie = useCookie(cookies.wsDataBaseUrl)
    const wsCartBaseUrlCookie = useCookie(cookies.wsCartBaseUrl)
    const wsErpBaseUrlCookie = useCookie(cookies.wsErpBaseUrl)
    const wsSivBaseUrlCookie = useCookie(cookies.wsSivBaseUrl)
    const wsCatalogPaBaseUrlCookie = useCookie(cookies.wsCatalogPaBaseUrl)

    if(status.value === 'success' && configResponse.value) {
        log("LOAD CONFIG...")
        configStore.setConfig(configResponse.value, supplierStockResponse.value, cdnUrl)

        wsDataBaseUrlCookie.value = wsDataBaseUrl.value
        wsCartBaseUrlCookie.value = wsCartBaseUrl.value
        wsErpBaseUrlCookie.value = wsErpBaseUrl.value
        wsSivBaseUrlCookie.value = wsSivBaseUrl.value
        wsCatalogPaBaseUrlCookie.value = wsCatalogPaBaseUrl.value

        log("CONFIG LOADED !")
    } else {
        createError({
            message: "Une erreur est survenue lors du chargement de la configuration du site, si le problème persiste, veuillez contacter le support.",
            statusCode: 500
        })
    }    
})