import { StockAvailabilityStatusCode } from "~/types/enums";
import type { AppTheme } from "~/types/global";

const _useTheme = () => {
    const configStore = useConfigStore()
    const { config, supplierStockStatus } = storeToRefs(configStore)
    const runtimeConfig = useRuntimeConfig()
    const isThemeLoaded = ref(false)

    const appTheme = ref<AppTheme>()

    function setTheme() {

        if(!config.value) {
            return createError({
                message: "Il semble avoir une erreur de configuration. Si le problème persiste merci de contacter le support.",
                statusCode: 500
            })
        }

        const available = supplierStockStatus.value.find(availabitily => availabitily.codeErp === StockAvailabilityStatusCode.Available) ?? null
        const availableSoon = supplierStockStatus.value.find(availabitily => availabitily.codeErp === StockAvailabilityStatusCode.AvailableSoon) ?? null
        const notAvailable = supplierStockStatus.value.find(availabitily => availabitily.codeErp === StockAvailabilityStatusCode.NotAvailable) ?? null
        const availableInLogistics = supplierStockStatus.value.find(availabitily => availabitily.codeErp === StockAvailabilityStatusCode.InLogistics) ?? null

        const lightTheme: AppTheme = {
            primaryColor1: config.value.theme.lightPrimaryColor1,
            primaryColor2: config.value.theme.lightPrimaryColor2,
            primaryColor3: config.value.theme.lightPrimaryColor3,
            primaryColor4: config.value.theme.lightPrimaryColor4,
            secondaryColor1: config.value.theme.lightSecondaryColor1,
            secondaryColor2: config.value.theme.lightSecondaryColor2,
            secondaryColor3: config.value.theme.lightSecondaryColor3,
            secondaryColor4: config.value.theme.lightSecondaryColor4,
            thirdColor1: config.value.theme.lightThirdColor1,
            thirdColor2: config.value.theme.lightThirdColor2,
            thirdColor3: config.value.theme.lightThirdColor3,
            thirdColor4: config.value.theme.lightThirdColor4,
            neutralColor1: config.value.theme.lightNeutralColor1,
            neutralColor2: config.value.theme.lightNeutralColor2,
            neutralColor3: config.value.theme.lightNeutralColor3,
            neutralColor4: config.value.theme.lightNeutralColor4,
            backColor1: config.value.theme.lightBackColor1,
            backColor2: config.value.theme.lightBackColor2,
            backColor3: config.value.theme.lightBackColor3,
            backColor4: config.value.theme.lightBackColor4,
            buttonFontColor: config.value.theme.lightFontColor,
            siteFontColor: config.value.theme.lightSiteFontColor,
            fontFamily: config.value.theme.fontFamily,
            roundedBorder: config.value.theme.roundedBorder,
            dispoColor: available && available.color2 ? available.color2 : available && available.color ? available.color : runtimeConfig.public.dispoColor,
            dispoSoonColor: availableSoon && availableSoon.color2 ? availableSoon.color2 : availableSoon && availableSoon.color ? availableSoon.color : runtimeConfig.public.dispoSoonColor,
            indispoColor: notAvailable && notAvailable.color2 ? notAvailable.color2 : notAvailable && notAvailable.color ? notAvailable.color : runtimeConfig.public.indispoColor,
            inLogisticsColor: availableInLogistics && availableInLogistics.color2 ? availableInLogistics.color2 : availableInLogistics && availableInLogistics.color ? availableInLogistics.color : runtimeConfig.public.inLogisticsColor,
            primaryColor50: config.value.theme.primaryColor50,
            primaryColor100: config.value.theme.primaryColor100,
            primaryColor200: config.value.theme.primaryColor200,
            primaryColor300: config.value.theme.primaryColor300,
            primaryColor400: config.value.theme.primaryColor400,
            primaryColor500: config.value.theme.primaryColor500,
            primaryColor600: config.value.theme.primaryColor600,
            primaryColor700: config.value.theme.primaryColor700,
            primaryColor800: config.value.theme.primaryColor800,
            primaryColor900: config.value.theme.primaryColor900,
            primaryColor950: config.value.theme.primaryColor950,

        }

        const darkTheme: AppTheme = {
            primaryColor1: config.value.theme.darkPrimaryColor1,
            primaryColor2: config.value.theme.darkPrimaryColor2,
            primaryColor3: config.value.theme.darkPrimaryColor3,
            primaryColor4: config.value.theme.darkPrimaryColor4,
            secondaryColor1: config.value.theme.darkSecondaryColor1,
            secondaryColor2: config.value.theme.darkSecondaryColor2,
            secondaryColor3: config.value.theme.darkSecondaryColor3,
            secondaryColor4: config.value.theme.darkSecondaryColor4,
            thirdColor1: config.value.theme.darkThirdColor1,
            thirdColor2: config.value.theme.darkThirdColor2,
            thirdColor3: config.value.theme.darkThirdColor3,
            thirdColor4: config.value.theme.darkThirdColor4,
            neutralColor1: config.value.theme.darkNeutralColor1,
            neutralColor2: config.value.theme.darkNeutralColor2,
            neutralColor3: config.value.theme.darkNeutralColor3,
            neutralColor4: config.value.theme.darkNeutralColor4,
            backColor1: config.value.theme.darkBackColor1,
            backColor2: config.value.theme.darkBackColor2,
            backColor3: config.value.theme.darkBackColor3,
            backColor4: config.value.theme.darkBackColor4,
            buttonFontColor: config.value.theme.darkFontColor,
            siteFontColor: config.value.theme.darkSiteFontColor,
            fontFamily: config.value.theme.fontFamily,
            roundedBorder: config.value.theme.roundedBorder,
            dispoColor: available && available.color2 ? available.color2 : available && available.color ? available.color : runtimeConfig.public.dispoColor,
            dispoSoonColor: availableSoon && availableSoon.color2 ? availableSoon.color2 : availableSoon && availableSoon.color ? availableSoon.color : runtimeConfig.public.dispoSoonColor,
            indispoColor: notAvailable && notAvailable.color2 ? notAvailable.color2 : notAvailable && notAvailable.color ? notAvailable.color : runtimeConfig.public.indispoColor,
            inLogisticsColor: availableInLogistics && availableInLogistics.color2 ? availableInLogistics.color2 : availableInLogistics && availableInLogistics.color ? availableInLogistics.color : runtimeConfig.public.inLogisticsColor,
            primaryColor50: config.value.theme.primaryColor50,
            primaryColor100: config.value.theme.primaryColor100,
            primaryColor200: config.value.theme.primaryColor200,
            primaryColor300: config.value.theme.primaryColor300,
            primaryColor400: config.value.theme.primaryColor400,
            primaryColor500: config.value.theme.primaryColor500,
            primaryColor600: config.value.theme.primaryColor600,
            primaryColor700: config.value.theme.primaryColor700,
            primaryColor800: config.value.theme.primaryColor800,
            primaryColor900: config.value.theme.primaryColor900,
            primaryColor950: config.value.theme.primaryColor950,
        }    

        // const isLightTheme = useMedia("(prefers-color-scheme: light)");
        
        let themeToLoad = lightTheme

        // if (!isLightTheme.value ) {
        //     themeToLoad = darkTheme;
        // }

        useColors(themeToLoad)

        appTheme.value = themeToLoad
        isThemeLoaded.value = true
    }

    return {
        setTheme,
        appTheme,
        isThemeLoaded
    }
};

export const useTheme = createSharedComposable(_useTheme)