import { useRuntimeConfig } from "#app";
export const useDebug = () => {
  function debug(title, ...values) {
    if (!useRuntimeConfig().public.dev)
      return;
    console.log(title, values);
  }
  function error(title, ...values) {
    if (!useRuntimeConfig().public.dev)
      return;
    console.error(title, values);
  }
  function warn(title, ...values) {
    if (!useRuntimeConfig().public.dev)
      return;
    console.warn(title, values);
  }
  return {
    debug,
    error,
    warn
  };
};
