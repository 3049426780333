<template>  
  <div id="app">
    <NuxtLoadingIndicator/>
    <NuxtLayout v-if="isLoaded">
      <ElementsToast/>
      <NuxtPage/>
    </NuxtLayout> 
    <VehicleLoader v-else/>
  </div>
</template>

<script
    lang="ts"
    setup
>
const configStore = useConfigStore()
const {config, supplierStockStatus} = storeToRefs(configStore)
const {setTheme, isThemeLoaded} = useTheme()
const {log} = useConsole()

const isLoaded = computed(() => config.value && isThemeLoaded.value)

onMounted(() => {
    setTheme()
    log(config.value, 'CONFIG')
    log(supplierStockStatus.value, 'supplierStockStatus')
})
</script>