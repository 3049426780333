import { createSharedComposable } from '@vueuse/core';
import type { Toast } from '~/types/interfaces';

const _useAppToast = () => {
    const toasts = ref<Toast[]>([])

    const addToast = (message: string, type: 'success' | 'error' | 'info', timeout: number = 3000) => {
        const id = Date.now()
        toasts.value.push({ id, message, type, timeout })
        useTimeoutFn(() => removeToast(id), timeout)
    }
    
    const removeToast = (id: number) => toasts.value = toasts.value.filter(toast => toast.id !== id)

    return {
        toasts,
        addToast,
        removeToast
    }
}
export const useAppToast = createSharedComposable(_useAppToast)
