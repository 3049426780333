import { createSharedComposable } from '@vueuse/core';

const _useUtilsFunctions = () => {
    function removeAccents(str: string) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    function capitalize(str:string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    function deleteDoubleByField<T>(array: T[], field: keyof T): T[] {
        const seen = new Set();
        return array.filter(object => {
            const value = object[field];
            return seen.has(value) ? false : seen.add(value);
        });
    }
    function deleteDoubleByFieldString<T>(array: T[], field: keyof T): T[] {
        const seen = new Set();
        return array.filter(object => {
            const value = object[field] as string;

            return seen.has(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) ? false : seen.add(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
        });
    }
    function groupByField<T>(array: T[], field: keyof T): T[][] {
        const groups: Record<string, T[]> = {};
        array.forEach(obj => {
            const valueField = obj[field] as string;
            if (!groups[valueField]) {
                groups[valueField] = [obj];
            } else {
                groups[valueField].push(obj);
            }
        });
        return Object.values(groups);
    }

    function dateAddDays(a: number, b?: string | Date) {
        var d = new Date(b || new Date());
        d.setDate(d.getDate() + a);
        return d;
    }
    function truncateString(string: string, maxLength: number) {
        return string.length > maxLength ? string.substring(0, maxLength) : string
    }
    const b64DecodeUnicode = (str: string) => {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }
    function splitArray<T>(array: T[], chunkSize: number): T[][] {
        if (!Array.isArray(array) || chunkSize <= 0) {
          throw new Error("Invalid input");
        }
      
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          const chunk = array.slice(i, i + chunkSize);
          result.push(chunk);
        }
        
        return result;
      }
    return {
        deleteDoubleByField,
        groupByField,
        deleteDoubleByFieldString,
        dateAddDays,
        truncateString,
        b64DecodeUnicode,
        splitArray,
        removeAccents,
        capitalize
    };
}

export const useUtilsFunctions = createSharedComposable(_useUtilsFunctions)



